<template>
  <div id="divCampaignListWrapper" class="campaigns-wrapper">
    <VueText sizeLevel="6" weightLevel="4" class="campaigns-wrapper-header"
      >Tüm Kampanyalar</VueText
    >
    <div
      v-for="item in campaigns"
      class="campaigns-wrapper-item"
      :key="item.campaignId"
      @click="goToDetail(item.campaignId, item.shortDescription)"
    >
      <div class="image-area">
        <img :src="item.imageUrl" alt="campaign" />
      </div>
      <div class="footer-area">
        <div class="footer-area-wrapper">
          <div class="footer-left">
            <VueText sizeLevel="5" weightLevel="3">{{ item.shortDescription }}</VueText>
          </div>
          <div class="footer-right">
            <BrandButton
              :size="sizes.xSmall"
              as="router-link"
              :target="{
                name: detailRoute,
                params: { id: item.campaignId },
              }"
            >
              <VueText color="white-100" sizeLevel="4" weightLevel="3">HEMEN TIKLA</VueText>
            </BrandButton>
          </div>
        </div>
      </div>
    </div>
    <BrandSelectModal
      v-if="filters"
      :submitFilterButton="true"
      :showModal="getFunPageModalStatus"
      title="Kampanyaları Filtrele"
      @click="applyFilter"
      @onModalClose="setModalStatus(false)"
    >
      <VueContentHolder class="filter-wrapper" :padding="['0', '20', '50']">
        <div class="filter-group" :key="renderKey">
          <div class="filter-header">
            Kampanya Tipi
          </div>
          <VueListItem
            :text="item.Value"
            @click="selectFilterItem('campaignTypes', i)"
            v-for="(item, i) in filters.campaignTypes"
            :key="'type_' + i"
            :icon="getFilterIcon(item.Selected)"
            :contentAlignment="constants.flexAlignment.between"
          ></VueListItem>
        </div>
        <div class="filter-group" :key="renderKey + 1">
          <div v-if="filters.tags && filters.tags.length > 0" class="filter-header">
            Markalar
          </div>
          <VueListItem
            :text="item.Value"
            @click="selectFilterItem('tags', i)"
            v-for="(item, i) in filters.tags"
            :key="'tag_' + i"
            :icon="getFilterIcon(item.Selected)"
            :contentAlignment="constants.flexAlignment.between"
          ></VueListItem>
        </div>
      </VueContentHolder>
    </BrandSelectModal>
  </div>
</template>
<script>
import { Campaign } from '@/services/Api/index';
import { mapGetters } from 'vuex';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import { COMPONENT_SIZES, ICON_VARIABLES } from '@/constants/component.constants.js';
import RoutesSecure from '@/router/routes/RoutesSecure';
import BrandSelectModal from '@/components/brand/Modals/BrandModal/BrandSelectModal.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import VueListItem from '@/components/shared/VueListItem/VueListItem.vue';
import gtmUtils from '@/mixins/gtmUtils';
export default {
  name: 'Campaigns',
  mixins: [gtmUtils],
  components: {
    BrandButton,
    VueText,
    BrandSelectModal,
    VueContentHolder,
    VueListItem,
  },
  data() {
    return {
      renderKey: 0,
      campaigns: null,
      filters: { contentTypes: [], tags: [] },
      detailRoute: RoutesSecure.CampaignDetail.name,
      filterModel: { contentTypes: [], tags: [], searchText: '' },
    };
  },
  computed: {
    ...mapGetters('app', ['getFunPageModalStatus', 'getSearchedText']),
    sizes() {
      return COMPONENT_SIZES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    icon() {
      return {
        filter: ICON_VARIABLES.filter,
        checked: ICON_VARIABLES.checked,
        circle: ICON_VARIABLES.circle,
        info: ICON_VARIABLES.info,
      };
    },
  },
  watch: {
    getSearchedText: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.filterModel['searchText'] = newVal;
          this.applyFilter();
        }
      },
    },
  },
  beforeMount() {
    Campaign.getCampaignFilters().then(res => {
      if (res && res.data && res.data.Data) {
        this.filters = Object.assign({}, res.data.Data);

        this.filters.tags.forEach((item, key, arr) => {
          let keyValuePair = { Key: item, Value: item.toString() };
          arr[key] = {
            Selected: false,
            ...keyValuePair,
          };
        });
        this.filters.campaignTypes.forEach((item, key, arr) => {
          let keyValuePair = { Key: item.contentTypeId, Value: item.contentName };
          arr[key] = {
            Selected: false,
            ...keyValuePair,
          };
        });

        this.getCampaigns();
      }
    });
  },

  methods: {
    goToDetail(campaignId, desc) {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('kampanyalar', {
        name: desc,
      });
      this.$router.push({
        name: this.detailRoute,
        params: { id: campaignId },
      });
    },
    selectFilterItem(key, index) {
      //item, filterKey, i
      this.renderKey += 1;
      this.filters[key][index].Selected = !this.filters[key][index].Selected;
    },
    getFilterIcon(selected) {
      return selected ? this.icon.checked : this.icon.circle;
    },
    applyFilter() {
      this.filterModel.contentTypes = [];
      this.filterModel.tags = [];

      Object.keys(this.filters).forEach(x => {
        this.filters[x].forEach(item => {
          if (item.Selected) {
            if (x == 'campaignTypes') {
              this.filterModel.contentTypes.push(Number(item.Key));
            } else {
              this.filterModel[x].push(item.Value);
            }
          }
        });
      });

      this.getCampaigns(this.filterModel);
      this.setModalStatus(false);
    },
    setModalStatus(status) {
      this.$store.dispatch('app/setFunPageModalStatus', status);
    },
    getCampaigns() {
      Campaign.getCampaigns(this.filterModel).then(res => {
        if (res && res.data && res.data.Data) {
          this.campaigns = res.data.Data.campaigns;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.campaigns-wrapper {
  width: 100%;
  padding: palette-space-level(10) palette-space-level(20);
  margin-bottom: palette-space-level(30);
  &-header {
    margin-bottom: palette-space-level(10);
  }
  &-item {
    overflow: hidden;
    border-radius: palette-space-level(5);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    &:not(:last-child) {
      margin-bottom: palette-space-level(20);
    }
    display: flex;
    flex-direction: column;
    .image-area {
      img {
      }
    }
    .footer-area {
      padding: palette-space-level(10) 0;
      &-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        div {
          padding: palette-space-level(10);

          &:first-child {
            border-right: 1px solid palette-color-level('grey', 20);
          }
        }
        .footer-left {
          width: 60%;
        }
        .footer-right {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .filter-wrapper {
    overflow: auto;
    max-height: 400px;
    .filter-group {
      margin-bottom: palette-space-level(20);
      margin-top: palette-space-level(20);
      .filter-header {
        padding-bottom: palette-space-level(10);
        border-bottom: 1px solid palete-color-level('grey', 20);
        font-size: palette-space-level(20) !important;
        font-weight: bold !important;
      }
    }
  }
}
</style>
